<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default uk-card-body uk-width-3-4@m uk-margin-auto">
            <h3 class="uk-card-title">Report Filter</h3>
            <hr>
            <div class="uk-form-controls uk-width-auto">
                <div class="uk-margin">
                    <label class="uk-form-label">Company</label>
                    <multiselect
                        v-model="meta.company_id"
                        placeholder="Select Company"
                        label="name"
                        name="company"
                        track-by="name"
                        :options="company_options"
                        :loading='isFetching'
                        @select="item => setOfficeValues(item.value)"
                        @remove="meta.company_office_id = null"
                        >
                    </multiselect>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Office</label>
                    <multiselect
                        v-model="meta.company_office_id"
                        placeholder="Select Company"
                        label="name"
                        name="office"
                        track-by="name"
                        :options="office_options"
                        :loading='isFetching'
                        :disabled="meta.company_id == null"
                        >
                    </multiselect>
                </div>
                <div class="uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="downloadUserReport">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatter from '@/utils/formatter';
import { excelDownloader } from '@/utils/helper';

export default {
    data() {
        return {
            meta: {
                company_id: null,
                company_office_id: null
            },
            isFetching: false,
            isLoading: false,
            company_options: [],
            office_options: [],
            roles_options: [
                { name: 'Candidate', value: 'zxc123'},
                { name: 'Employer', value: 'asd123'}
            ],
            company: {
                offices: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            companies: 'company/companies',
            company_data: 'company/company',
        })
    },
    mounted() {
        this.setCompanyValues();
    },
    methods: {
        ...mapActions({
            getCompanies: 'company/getCompanies',
            getCompanyDetail: 'company/getCompanyDetail',
            getMitraReport: 'report/getMitraReport',
        }),
        async setCompanyValues() {
            this.isFetching = true;

            await this.getCompanies();
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setOfficeValues(company_id) {
            this.isFetching = true;

            await this.getCompanyDetail(company_id.toString());
            this.company = this.company_data;
            this.office_options = this.company.offices.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async downloadUserReport() {
            this.isLoading = true;
            let param = {
                company_id: this.meta.company_id != null ? this.meta.company_id.value : '',
                company_office_id: this.meta.company_office_id != null ? this.meta.company_office_id.value : ''
            };
            let all_recaps = await this.getMitraReport(param);
            for (const item of all_recaps) {
                item['Created At'] = formatter.datetimeShort(item['Created At']);
            }

            excelDownloader(all_recaps, this.generateFileName());
            this.isLoading = false;
        },
        generateFileName() {
            let suffixName = "";
            const cId = this.meta.company_id != null ? this.meta.company_id.name : '';
            const coId = this.meta.company_office_id != null ? this.meta.company_office_id.name : '';
            if (!cId && !coId) {
                suffixName = ``;
            } else if (!coId){
                suffixName = ` @ ${cId}`;
            } else {
                suffixName = ` @ ${cId} - ${coId} `;
            }
            let filename = `Mitra Report${suffixName}.xls`;
            return filename;
        },
    },
};
</script>
